import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <footer className="gap footer-one no-bottom green-overlay">
                <div
                    className="parallax"
                    style={{ backgroundImage: "url(assets/images/footer-bg.webp)" }}
                />
                <div className="container">
                    <div className="row part-one">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className='section-headings'>
                                <h2 className="text-white entry-title">History</h2>
                            </div>
                            <div className='d-flex mt-5'>
                                <a href="/">
                                    <img
                                        className="footer-logo"
                                        src="assets/images/home/headlogo.png"
                                        alt="footer logo"
                                    />
                                </a>
                                <p className='text-white'>
                                We are a community of religious women, belonging to the Institute of the
                                </p>
                            </div>
                            <p className='text-white'>
                                Blessed Virgin Mary a multicultural, international Institute founded by an English woman, Mary Ward.  We are known informally as Loreto Sisters. The IBVM comprised of three branches: &nbsp;<Link to={'/history'} style={{
                                    color: "#f6c93f"
                                }} className='foothover'>Read More</Link>
                            </p>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className='section-headings'>
                                <h2 className="text-white entry-title">Quick Links</h2>
                            </div>
                            <ul className="quick-links">
                                <li>
                                    <Link to={'/history'}>History</Link>
                                </li>
                                <li>
                                    <Link to={'/newsletter'}>News Letter</Link>
                                </li>
                                <li>
                                    <Link to={'/loreto-india'}>Loreto in India</Link>
                                </li>
                                <li>
                                    <Link to={'/education'}>Education</Link>
                                </li>
                                <li>
                                    <Link to={'/fcra'}>FCRA Information</Link>
                                </li>
                                <li>
                                    <Link to={'/contactus'}>Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className='section-headings'>
                                <h2 className="text-white entry-title">Contact Us</h2>
                            </div>
                            <ul className="quick-links">
                                <li>
                                    <p className="text-white">
                                        <span className="theme-clr"><i className='fa fa-map-marker'></i></span>&nbsp;&nbsp;7 Middleton Row Kolkata , 700071 India
                                    </p>
                                </li>
                                <li>
                                    <p className="text-white">
                                        <span className="theme-clr"><i className='fa fa-phone'></i></span>&nbsp;&nbsp;<a href='tel:033-4006 1156' className='footmobile'>033-4006 1156</a>
                                    </p>
                                </li>
                                <li>
                                    <p className="text-white">
                                        <span className="theme-clr"><i className='fa fa-envelope'></i></span>&nbsp;&nbsp;<a href='mailto:provsecsa@loreto.in' className='footmobile'>provsecsa@loreto.in</a>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className='section-headings'>
                                <h2 className="text-white entry-title">Our Location</h2>
                            </div>
                            <ul className="footer-events">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.7730007234613!2d88.34968917593255!3d22.550174633790775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a02771079019917%3A0x936dfcbc761465b5!2s7%2C%20Sir%20William%20Jones%20Sarani%2C%20Park%20Street%20area%2C%20Kolkata%2C%20West%20Bengal%20700071!5e0!3m2!1sen!2sin!4v1710998858380!5m2!1sen!2sin"
                                    width="100%"
                                    height={200}
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    title='loreto'
                                    referrerPolicy="no-referrer-when-downgrade"
                                />
                            </ul>
                        </div>
                    </div>
                    <div className="footer-rights">
                        <p className="text-white text-center" style={{fontSize:"14px"}}>
                            © Copyright <span>2024  <a href="https://boscosofttech.com/" target='_blank' rel='noreferrer' className='foothover' style={{ color: "#f6c93f" }}>
                                BoscoSoft Technologies
                            </a></span> All Rights Reserved.
                        </p>
                    </div>
                </div>
            </footer >

        </>
    )
}

export default Footer
