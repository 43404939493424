import React, { useState, useEffect } from "react";
import axios from "axios";
import "./FlashNews.css";
import { Link } from "react-router-dom";
import {  ReligioUrl } from "../SubPages/API/Api";

function FlashNews() {
    const [latestNews, setLatestNews] = useState([]);

    useEffect(() => {
        axios
            .get(`${ReligioUrl}/news/province/1`)
            .then((response) => {
                setLatestNews(response?.data?.data);
            })
            .catch((error) => {
                console.error("Error fetching latest news:", error);
            });
    }, []);

    if (latestNews.length === 0) {
        return (
            <div className="scrollbar">
                <div className="container">
                    <div className="row flex-wrap justify-content-center justify-content-lg-between align-items-lg-center">
                        <div className="col-4 col-lg-2 d-flex">
                            <div className="label ripple">Latest News</div>
                        </div>
                        <div className="col-8 col-lg-10 d-md-flex flex-wrap justify-content-center justify-content-lg-start mb-3 mb-lg-0">
                            <div className="marqueenews">
                                <div className="marquee">
                                    <p>
                                        <span>&nbsp;No Flash News Available</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="scrollbar">
            <div className="container">
                <div className="row flex-wrap justify-content-center justify-content-lg-between align-items-lg-center">
                    <div className="col-4 col-lg-2 d-flex">
                        <div className="label ripple">Latest News</div>
                    </div>
                    <div className="col-8 col-lg-10 d-md-flex flex-wrap justify-content-center justify-content-lg-start mb-3 mb-lg-0">
                        <div className="marqueenews">
                            <div className="marquee">
                                <p>
                                    {latestNews?.map((newsItem, index) => (
                                        <span key={index}>
                                            &nbsp;<img
                                                src="assets/images/home/new.gif"
                                                style={{
                                                    maxWidth: "40px",
                                                }}
                                                alt=""
                                            />
                                            <Link
                                                to={newsItem?.link}
                                                style={{ color: "white", textDecoration: "none" }}
                                            >
                                                {newsItem?.name}
                                            </Link>
                                        </span>
                                    ))}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FlashNews;
