import React, { useEffect, useState } from "react";
import axios from "axios";
import { Outlet } from "react-router-dom";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { ApiUrl } from "../SubPages/API/Api";
import { InfinitySpin } from "react-loader-spinner";

const MainLayout = () => {
  const [loading, setLoading] = useState(true);
  const [homedata, setHomedata] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/homepagee/sections`);
        localStorage.setItem("HomeData", JSON.stringify(response?.data?.data));
        setHomedata(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <InfinitySpin
          visible={true}
          width="200"
          color="#800000"
          ariaLabel="infinity-spin-loading"
        />
      </div>
    );
  }

  return (
    <>
      <Header menudata={homedata?.headermenudata} />
      <div style={{ minHeight: "66.5vh", padding: "10px", marginTop: "100px" }}>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default MainLayout;
