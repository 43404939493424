import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './News.css';
import { Link } from 'react-router-dom';
import { ReligioUrl } from '../SubPages/API/Api';

const News = () => {
    const [latestNews, setLatestNews] = useState(() => {
        const storedNews = localStorage.getItem('latestNews');
        return storedNews ? JSON.parse(storedNews) : [];
    });

    useEffect(() => {
        axios
            .get(`${ReligioUrl}/news/province/1`)
            .then((response) => {
                const newsData = response?.data?.data;
                setLatestNews(newsData);
                localStorage.setItem('latestNews', JSON.stringify(newsData));
            })
            .catch((error) => {
                console.error("Error fetching latest news:", error);
            });
    }, []);

    if (!latestNews) {
        return (
            <div className='container'>
                <div className="section-headings">
                    <h4 className="text-black entry-title">News & Events</h4>
                    <br />
                    <div><b>Loading...</b></div>
                </div>
            </div>
        );
    }

    const sortedNews = latestNews?.sort((a, b) => b.id - a.id);

    const latest4News = sortedNews?.slice(0, 4);

    return (
        <div className='container mt-3'>
            <div className='section-heading'>
                <h4 className="text-black entry-title">News & Events</h4>
            </div>
            <br />
            <div className="row mt-2 mb-2">
                {latest4News?.map((newsItem, index) => (
                    <div className="col-md-3" key={index}>
                        <div className="tpn_card" id='news-back'>
                            <img src={newsItem?.upload_image[0] || 'assets/images/home/noimage.jpg'} className="w-100 mb-4" alt="newsItem" />
                            <h5>{newsItem?.name || 'Untitled'}</h5>
                            <p className='mt-2'>{newsItem.date || 'N/A'}</p>
                            <Link to={'newsevents'} className="theme-btn" id='homebtnnew'>
                                Read More
                            </Link>
                        </div>
                    </div>
                ))}
                {
                    latest4News.length === 0 && <p><b>No News Available</b></p>
                }
            </div>
        </div>
    );
}

export default News;
