import React from 'react';
import './maryward.css';

const Maryward = () => {
    const pdfPath = '/assets/images/subpages/MW.pdf';

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = process.env.PUBLIC_URL + pdfPath;
        link.download = 'Maryward_PDF.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="pdf-viewer-container mt-5">
            <div className="pdf-header">
                <h1>VENERABLE MARY WARD, I.B.V.M</h1>
            </div>
            <div className="pdf-viewer">
                <iframe
                    title="PDF Viewer"
                    src={process.env.PUBLIC_URL + pdfPath}
                    width="100%"
                    height="500px"
                    frameBorder="0"
                    className='pdfframe'
                />
                <button className="download-btn" onClick={handleDownload}>
                    Download PDF
                </button>
            </div>
        </div>
    );
};

export default Maryward;
