import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { ReligioUrl } from '../API/Api';

const columns = [  
    {
        name: 'Image',
        selector: 'image',
        wrap: true,
        cell: row => <img src={row.image || 'assets/images/noimage.webp'} alt="No Data" height="100" width="100" />,
    },
    {
        name: 'Member Name',
        selector: 'member_name',
        wrap: true,
        cell: row => <p className='text-truncate'>{row.member_name}</p>
    },
    {
        name: 'DOB',
        selector: 'dob',
        wrap: true,
        cell: row => <p className='text-truncate'>{row.dob}</p>
    },
    {
        name: 'Mobile',
        selector: 'mobile',
        wrap: true,
        cell: row => <p className='text-truncate'>{row.mobile}</p>
    },
    {
        name: 'Community ID',
        selector: 'community_id',
        wrap: true,
        cell: row => <p className='text-truncate'>{row.community_id}</p>
    },
    {
        name: 'Role IDs',
        selector: 'role_ids',
        wrap: true,
        cell: row => <p className='text-truncate'>{row.role_ids}</p>
    }
];

const jsonStyle = {
    padding: '10px',
    backgroundColor: '#f4f4f4',
    borderRadius: '5px',
    border: '1px solid #ccc',
    overflowX: 'auto'
};

function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];

            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}

function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
}

const Export = ({ data }) => <Button onClick={() => downloadCSV(data)}>Export</Button>;

const Birthday = () => {
    const [birthdayData, setBirthdayData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        axios.get(`${ReligioUrl}/member/province/birthday/this_month/1`)
            .then(response => {
                setBirthdayData(response?.data?.data);
                setFilteredData(response?.data?.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching birthday data:', error);
                setLoading(false);
            });
    }, []);

    const handleSearch = e => {
        const value = e.target.value.toLowerCase();
        const filtered = birthdayData.filter(row =>
            Object.values(row).some(
                val => typeof val === 'string' && val.toLowerCase().includes(value)
            )
        );
        setFilteredData(filtered);
    };

    return (
        <>
            <div className="section-headings mt-5 text-center mb-4">
                <h3 className="text-black entry-title">Birthday Member Details</h3>
            </div>
            <div className='container'>
                {loading ? (
                    <p><b><center>Loading...</center></b></p>
                ) : (
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        responsive
                        selectableRows
                        selectableRowsHighlighted
                        selectableRowsNoSelectAll
                        pagination
                        highlightOnHover
                        expandableRowsComponent={ExpandedComponent}
                        striped
                        pointerOnHover
                        subHeader
                        subHeaderComponent={<input type="text" placeholder="Search" className="form-control" onChange={handleSearch} />}
                        subHeaderComponentProps={{
                            className: 'sub-header',
                            style: {
                                backgroundColor: '#f8f9fa',
                                border: '1px solid #dee2e6',
                                borderRadius: '5px',
                                padding: '10px',
                                marginBottom: '10px'
                            }
                        }}
                        fixedHeader
                        actions={<Export data={filteredData} />}
                    />
                )}
            </div>
        </>
    );
};

const ExpandedComponent = ({ data }) => <pre style={jsonStyle}>{JSON.stringify(data, null, 2)}</pre>;

export default Birthday;
