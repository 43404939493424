import axios from "axios";
import { ApiUrl } from "./API/Api";
import { Link } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import "./gallerystyle.css";
import { ReligioUrl } from '../SubPages/API/Api';
import { useEffect, useState } from 'react';
import './newstyle.css';
const GalleryNews = () => {
    const [isLoading, setIsLoading] = useState(true);

    const [birthdayData, setBirthdayData] = useState(() => {
        const storedBirthday = localStorage.getItem('birthdayData');
        return storedBirthday ? JSON.parse(storedBirthday) : [];
    });

    useEffect(() => {
        axios
            .get(`${ReligioUrl}/member/province/birthday/this_month/1`)
            .then((response) => {
                const extractedData = response.data.data.map(item => ({
                    membername: item.member_name,
                    dob: item.dob,
                    image: item.image,
                    id: item.id
                }));
                setBirthdayData(extractedData);
                localStorage.setItem('birthdayData', JSON.stringify(extractedData));
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching latest news:", error);
                setIsLoading(false);
            });
    }, []);


    const [galleryimages, setgalleryImages] = useState(() => {
        const storedGallery = localStorage.getItem('galleryimages');
        return storedGallery ? JSON.parse(storedGallery) : [];
    });

    useEffect(() => {
        axios
            .get(`${ApiUrl}/get/gallery_images`)
            .then((response) => {
                const imageData = response?.data?.data;
                setgalleryImages(imageData);
                localStorage.setItem('galleryimages', JSON.stringify(imageData));
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching latest news:", error);
                setIsLoading(false);
            });
    }, []);


    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.toLocaleString("default", {
        month: "long",
    });

    const upcomingBirthdays = birthdayData.filter((item) => {
        const dobParts = item.dob.split(" - ");
        const dobDay = parseInt(dobParts[0], 10);
        const dobMonth = dobParts[1].trim();
        if (dobMonth === currentMonth) {
            return dobDay >= currentDay;
        }
        return true;
    });
    const first5UpcomingBirthdays = upcomingBirthdays.slice(0, 4);

    return (
        <>
            <section className="gap blog">
                <div className="container mt-3">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="section-headings">
                                <h4 className="text-black entry-title">Gallery</h4>
                            </div>
                            <div className="scrollable-content mt-5">
                                {galleryimages.length === 0 ? (
                                    <p style={{ marginTop: '30px' }}>
                                        <b>Loading...</b>
                                    </p>
                                ) : galleryimages.length === 0 ? (
                                    <p style={{ marginTop: '30px' }}>
                                        <b>No gallery images available</b>
                                    </p>
                                ) : (
                                    <ImageGallery items={galleryimages?.map(image => ({
                                        original: image.image || '',
                                        thumbnail: image.image || ''
                                    }))} />
                                )}
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className='section-heading'>
                                <h4 className="text-black entry-title">Upcoming Birthday / Feast</h4>
                            </div>
                            <div className="scrollable-content mt-5" id="provincialprog">
                                {isLoading ? (
                                    <p style={{ marginTop: "30px" }}>
                                        <b>Loading...</b>
                                    </p>
                                ) : first5UpcomingBirthdays.length > 0 ? (
                                    first5UpcomingBirthdays?.map((birthdayItem, index) => {
                                        const dobParts = birthdayItem.dob.split(" - ");
                                        const dobDay = parseInt(dobParts[0], 10);
                                        const dobMonth = dobParts[1].trim();
                                        const isBirthdayToday =
                                            dobDay === currentDay && dobMonth === currentMonth;
                                        const imageSrc = birthdayItem.image
                                            ? birthdayItem.image
                                            : "assets/images/noimage.webp";

                                        return (

                                            <article key={index} className="rcardnew shadow curve">
                                                <div className="mb-3">
                                                    <img
                                                        src={imageSrc}
                                                        alt="noimage"
                                                        className="img-fluid"
                                                    />
                                                </div>
                                                <div>
                                                    <p>
                                                        <Link to={'birthdaycalendar'} style={{ textDecoration: "none" }}>
                                                            <strong style={{ color: "black" }}>
                                                                {birthdayItem.membername || 'Untitled'}
                                                            </strong>
                                                        </Link>
                                                    </p>
                                                    <span style={{ fontSize: 14 }}>
                                                        &nbsp;<time>{birthdayItem.dob || 'N/A'}</time>
                                                    </span>
                                                </div>
                                                {isBirthdayToday && (
                                                    <img
                                                        src="assets/images/home/birthday.gif"
                                                        alt="Happy Birthday GIF"
                                                        className="birthday-gif"
                                                    />
                                                )}
                                            </article>
                                        );
                                    })
                                ) : (
                                    <p style={{ color: "black", fontWeight: "bold", marginTop: "3rem" }}>
                                        <br />
                                        No Birthday Wishes Available
                                    </p>
                                )}
                                <br />
                                <div className="text-center">
                                    {first5UpcomingBirthdays.length >= 4 && (
                                        <Link
                                            to={"birthday"}
                                            className="btn-sm"
                                            style={{
                                                backgroundColor: "rgb(128 0 0)",
                                                color: "#e0ab08",
                                                textDecoration: "none",
                                            }}
                                        >
                                            View More
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default GalleryNews;

