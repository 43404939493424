import React from 'react'
import { Link } from 'react-router-dom';

const AboutUs = () => {
    return (
        <>
            <section className="gap fellowships">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-8 col-md-8 col-sm-12"
                        >
                            <h1 className="text-left" style={{ textTransform: 'uppercase',fontSize:'34px'}}>
                                Welcome to Institute of Blessed Virgin Mary
                            </h1>
                            <div className="offer text-left">
                                <p className="mx-auto mt-3">
                                    We are a community of religious women, belonging to the Institute of the Blessed Virgin Mary a multicultural, international Institute founded by an English woman, Mary Ward. We are known informally as Loreto Sisters. The IBVM comprised of three branches: the Roman Branch, descendants of the original group of followers descended from Mary Ward, the Irish Branch, consisting of foundations of the IBVM made from Ireland and the North.
                                </p>
                                <div className="buttons-container mt-4">
                                   <Link to={'/aboutus'} className="theme-btn btn-sm" id='homebtnnew'>
                                        Read More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 mt-3'>
                            <img src="assets/images/home/maryward_home.png" className="img-fluid" alt='noimage' />
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AboutUs
