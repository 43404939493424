import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, ListGroup, Pagination } from 'react-bootstrap';
import styled from 'styled-components';
import { ReligioUrl } from '../API/Api';

const EventCard = styled(ListGroup.Item)`
  list-style-type: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  padding: 1.25rem;
  margin-bottom: 1rem;
`;

const Title = styled.h5`
  margin-bottom: 0.5rem;
`;

const Date = styled.small`
  color: #6c757d;
`;

const Content = styled.p`
  margin-top: 0.5rem;
`;

const goBack = () => {
    window.history.back();
};

const NewsEvents = () => {
    const [latestNews, setLatestNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const eventsPerPage = 3;

    useEffect(() => {
        axios
            .get(`${ReligioUrl}/news/province/1`)
            .then((response) => {
                setLatestNews(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching latest news:", error);
                setLoading(false);
            });
    }, []);

    const indexOfLastEvent = currentPage * eventsPerPage;
    const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
    const currentEvents = latestNews.slice(indexOfFirstEvent, indexOfLastEvent);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    if (loading) {
        return <div><p className='mt-5 text-center'><b>Loading...</b></p></div>;
    }

    if (latestNews.length === 0) {
        return <div><p className='mt-5 text-center'><b>No News Available</b></p></div>;
    }

    return (
        <Container className="mt-3">
            <div className="pdf-header mt-5">
                <h3>News & Events</h3>
            </div>
            <ListGroup>
                {currentEvents.map((event) => (
                    <EventCard key={event.id}>
                        <div className="row">
                            <div className="col-md-9">
                                <div>
                                    <Title style={{ fontWeight: "bold" }}>{event.name}</Title>
                                    <Date style={{ fontWeight: "bold" }}>
                                        <i className='fa fa-calendar'></i>&nbsp;&nbsp;&nbsp;{event.date}<br />
                                    </Date>
                                    <Content>
                                        <p dangerouslySetInnerHTML={{ __html: event.description }} style={{ textAlign: "justify" }} />
                                    </Content>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <img src={event.upload_image[0] || "assets/images/home/noimage.jpg"} style={{ width: "250px", height: "150px" }} alt='latestNews'></img>
                            </div>
                        </div>
                    </EventCard>
                ))}
            </ListGroup>

            <Pagination className="mt-3">
                {Array.from({ length: Math.ceil(latestNews.length / eventsPerPage) }, (_, index) => (
                    <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => paginate(index + 1)}
                    >
                        {index + 1}
                    </Pagination.Item>
                ))}
            </Pagination>
            <br />
            <center><button onClick={goBack} className='btn btn-primary btn-sm'>Go Back</button></center>
        </Container>
    );
};

export default NewsEvents;
