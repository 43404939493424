import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./layout/partials/Header";
import Slider from "./Components/Slider";
import Footer from "./layout/partials/Footer";
import { ApiUrl } from "./Components/API/Api";
import AboutUs from "./Components/AboutUs";
import GalleryNews from "./Components/GalleryNews";
import Scrollbar from "./Components/FlashNews";
// import OurMember from "./Components/OurMembers";
import News from "./Components/RecentBlog";
import { InfinitySpin } from "react-loader-spinner";

const Home = () => {
  const [homedata, setHomedata] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/get/homepagee/sections`);
        localStorage.setItem("HomeData", JSON.stringify(response?.data?.data));
        setHomedata(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {isLoading && !homedata && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <InfinitySpin
            visible={true}
            width={200}
            color="#800000"
            ariaLabel="infinity-spin-loading"
          />
        </div>
      )}

      {!isLoading && (
        <>
          <Header menudata={homedata?.headermenudata} />
          <Slider sliderdata={homedata?.SlidesData} />
          <Scrollbar />
          <AboutUs />
          {/* <OurMember /> */}
          <News />
          <GalleryNews />
          <Footer />
        </>
      )}
    </>
  );
};

export default Home;
