import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Header = ({ menudata }) => {
    const location = useLocation();
    const url = location.pathname;

    const [activeMenu, setActiveMenu] = useState(null);
    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const handleMenuClick = (index) => {
        setActiveMenu(activeMenu === index ? null : index);
    };

    const handleSubMenuClick = (index) => {
        setActiveSubMenu(activeSubMenu === index ? null : index);
    };

    return (
        <>
            {/* Desktop Header */}
            <header className="header-one">
                <div className="top-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <ul className="login">
                                    <li>
                                        <span className="theme-clr"><i className='fa fa-phone'></i></span>&nbsp;&nbsp;<a href='tel:033-4006 1156' className='footmobile'>033-4006 1156</a>
                                    </li>
                                    <li>
                                        <span className="theme-clr"><i className='fa fa-envelope'></i></span>&nbsp;&nbsp;<a href='mailto:provsecsa@loreto.in' className='footmobile'>provsecsa@loreto.in</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-2 offset-1">
                                <ul className="social-medias">
                                    <li>
                                        <a href="/">
                                            <img src="assets/images/facebook.svg" alt="facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <img src="assets/images/twitter.svg" alt="twitter" style={{ width: "20px" }} />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <img src="assets/images/instagram.svg" alt="instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <img src="assets/images/home/linkedin.png" alt="linkedin" style={{ width: "20px" }} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="desktop-nav" id="stickyHeader">
                    <div className="headercontainer">
                        <div className="row">
                            <div className="col-lg-12">
                                <nav>
                                    <div className="logo">
                                        <Link to={'/'}>
                                            <img src="assets/images/home/logo.png" alt="Logo" id='homebtnnewleft' />
                                        </Link>
                                    </div>
                                    <div className="nav-bar">
                                        <ul>
                                            {menudata?.map((menuItem, index) => (
                                                <li key={index} className={menuItem.children ? 'menu-item-has-children' : 'menu-item'}>
                                                    {menuItem.children ? (
                                                        <Link to={menuItem.url} className={`${menuItem.children.some(child => url.includes(child.url)) ? "activemain" : ""}`}>
                                                            {menuItem.label}
                                                        </Link>
                                                    ) : (
                                                        <Link to={menuItem.url} className={`${url === menuItem.url ? "activemain" : ""}`} style={{ whiteSpace: 'nowrap' }} >
                                                            {menuItem.label}
                                                        </Link>
                                                    )}
                                                    {menuItem.children && (
                                                        <ul className="sub-menu">
                                                            {menuItem.children?.map((subItem, subIndex) => (
                                                                <li key={subIndex} className={subItem.subchildren ? 'menu-item-has-children' : 'menu-item'}>
                                                                    <Link to={subItem.url}>
                                                                        {subItem.label}
                                                                    </Link>
                                                                    {subItem.subchildren && (
                                                                        <ul className="sub-menu">
                                                                            {subItem.subchildren.map((childSubItem, childSubIndex) => (
                                                                                <li key={childSubIndex} className="menu-item">
                                                                                    <Link to={childSubItem.url}>
                                                                                        {childSubItem.label}
                                                                                    </Link>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    )}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="donation">
                                    </div>
                                    <div id="nav-icon4"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasScrolling"
                                        aria-controls="offcanvasScrolling">
                                        <span />
                                        <span />
                                        <span />
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* Mobile View Menu */}
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex={-1} id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                <div className="offcanvas-header">
                    <h5>Loreto Province - Kolkata</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className="logo">
                        <Link to={'/'}>
                            <img src="assets/images/home/logo.png" alt="Logo" id='homebtnnewleft' />
                        </Link>
                    </div>
                    <div className="nav-bar mt-3">
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {menudata?.map((menuItem, index) => (
                                <li key={index} className={menuItem.children ? 'menu-item-has-children' : 'menu-item'} style={{ display: 'block', position: 'relative' }}>
                                    {menuItem.children ? (
                                        <a href={() => false} onClick={() => handleMenuClick(index)} style={{ display: 'block', padding: '10px', textDecoration: 'none', color: 'black' }}>{menuItem.label}</a>
                                    ) : (
                                        <a href={menuItem.url} style={{ display: 'block', padding: '10px', textDecoration: 'none', color: 'black' }}>{menuItem.label}</a>
                                    )}
                                    {menuItem.children && (
                                        <Collapse in={activeMenu === index}>
                                            <ul className="sub-menu" style={{ listStyleType: 'none', padding: 0, position: 'absolute', top: '100%', left: '0', zIndex: 1, backgroundColor: 'lightgray', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0,0,0,0.2)' }}>
                                                {menuItem.children.map((subItem, subIndex) => (
                                                    <li key={subIndex} className={subItem.subchildren ? 'menu-item-has-children' : 'menu-item'} style={{ display: 'block', position: 'relative' }}>
                                                        {subItem.subchildren ? (
                                                            <a href={() => false} onClick={(e) => { e.preventDefault(); handleSubMenuClick(subIndex); }} style={{ display: 'block', padding: '10px', textDecoration: 'none', color: 'black' }}>{subItem.label}</a>
                                                        ) : (
                                                            <a href={subItem.url} style={{ display: 'block', padding: '10px', textDecoration: 'none', color: 'black' }}>{subItem.label}</a>
                                                        )}
                                                        {subItem.subchildren && (
                                                            <Collapse in={activeSubMenu === subIndex}>
                                                                <ul className="sub-menu" style={{ listStyleType: 'none', padding: 0, position: 'absolute', top: '0', left: '100%', zIndex: 1, backgroundColor: 'lightgray', borderRadius: '5px', boxShadow: '0 2px 5px rgba(0,0,0,0.2)' }}>
                                                                    {subItem.subchildren.map((childSubItem, childSubIndex) => (
                                                                        <li key={childSubIndex} className="menu-item" style={{ display: 'block' }}>
                                                                            <a href={childSubItem.url} style={{ display: 'block', padding: '10px', textDecoration: 'none', color: 'black' }}>{childSubItem.label}</a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </Collapse>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        </Collapse>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;
