import React from 'react';
import { useLocation } from 'react-router-dom';
import { ApiUrl } from '../../Components/API/Api';
import useCachedData from '../../useCachedData';

function BecomeLoretoSisters() {
  const location = useLocation();
  const url = location.pathname;

  const path = {
    '/become-loreto-sister':20 
  };
  const pageId = path[url] ? path[url] : url;

  const { data, loading } = useCachedData(`${ApiUrl}/get/Pages/${pageId}`, pageId);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <b>Loading...</b>
      </div>
    );
  }

  const filteredData = data?.filter((item) => item.id === pageId);

  return (
    <>
      <div className="container subpage mt-5">
        <div className="row">
          <div className="col-lg-12">
            {filteredData?.map((item) => (
              <div key={item.id}>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: `${item.content}`,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <style jsx>{`
        @media (max-width: 768px) {
          .content {
            text-align: justify !important;
            font-size: 14px !important;
          }

          .content img {
            max-width: 100% !important;
            height: auto !important;
          }

          .content table {
            width: 100% !important;
            border-collapse: collapse !important;
          }

          .content table th,
          .content table td {
            padding: 8px !important;
            border: 1px solid #ddd !important;
          }
        }
      `}</style>
    </>
  );
}

export default BecomeLoretoSisters;